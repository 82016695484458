<template>
    <el-dialog
        :title="mode == 'add' ? '新增轮播图' : '编辑轮播图'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="标题">
                <el-input v-model="form.title" placeholder="请输入标题" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="内容">
                <el-input type="textarea" :rows="2" v-model="form.content" placeholder="请输入内容" size="medium"></el-input>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-form-item label="排序" prop="seq">
                            <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
                        </el-form-item>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-form-item label="是否上线" prop="onlineFlag">
                            <el-radio-group v-model="form.onlineFlag" size="medium">
                                <el-radio :label="true">上线</el-radio>
                                <el-radio :label="false">下线</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </el-col>
            </el-row>
            <el-form-item label="类型" prop="type">
                <el-select v-model="form.type" placeholder="请选择类型" size="medium">
                    <el-option v-for="item in typeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上传图片" prop="image" class="upload-form-items">
                <el-upload
                    class="upload-demo"
                    drag
                    action="none"
                    accept=".png,.jpg,.jpe,.jpeg,.jif,.jfif,.jfi,.PNG,.JPG,.JPEG,.JPE,.svg"
                    :multiple="false"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleUploadFile">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
                <div v-if="form.image">
                    <img :src="form.image | uploadFileUrl()" alt="" v-if="form.image.indexOf('http') == -1">
                    <img :src="form.image" alt="" v-else>
                </div>
            </el-form-item>
            <el-form-item label="链接地址" prop="url">
                <el-input v-model="form.url" placeholder="请输入链接地址" size="medium"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                title: '',
                content: '',
                type: '',
                seq: 1,
                onlineFlag: true,
                image: null,
                url: ''
            },
            rules: {
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ],
                onlineFlag: [
                    { required: true, message: '请选择是否上线', trigger: 'change' }
                ],
                image: [
                    { required: true, message: '请选择图片', trigger: 'change' }
                ],
                url: [
                    { required: true, message: '请输入链接地址', trigger: 'blur' }
                ],
            },
            typeList: [{
                label: 'banner',
                value: 0
            }, {
                label: '固定',
                value: 1
            }]
        };
    },
    methods: {
        onLoad(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    title: '',
                    content: '',
                    type: '',
                    seq: 1,
                    onlineFlag: true,
                    image: null,
                    url: ''
                }
            } else {
                this.getDetail(row.id);
            }

            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getDetail(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'rotationDetail'
            }).then(res=>{
                if(res.code == 0) {
                    this.form = {
                        id: res.data.id,
                        title: res.data.title,
                        content: res.data.content,
                        type: res.data.type,
                        seq: Number(res.data.seq),
                        onlineFlag: res.data.onlineFlag,
                        image: res.data.image,
                        url: res.data.url
                    }
                }
            })
        },
        // 上传
        handleUploadFile(file, fileList) {
            // 获取中文件后缀
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const fileFormat = ['png', 'jpg', 'jpe', 'jpeg', 'jif', 'jfif', 'jfi', 'PNG', 'JPG', 'JPEG', 'JPE', 'svg'];
            if (fileFormat.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是png、jpg、jpe、jpeg、jif、jfif、jfi、PNG、JPG、JPEG、JPE、svg格式');
                return false;
            }
            // 请求接口
            var fd = new FormData();
            fd.append('file', file.raw);

            this.$store.dispatch('manage/public', {
                query: fd,
                requestInterfaceName: 'uploadFile'
            }).then(res=>{
                if(res.code == 0) {
                    this.$message.success('上传成功');
                    this.form.image = res.data;
                } else {
                    this.$message.error(res.message);
                    this.form.image = null;
                }
            })
           
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.image = this.form.image.slice(this.form.image.indexOf('dev')),

                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addRotation' : 'editRotation',
                        listInterfaceName: 'getRotationList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .upload-form-items {
       .el-form-item__content {
            display: flex;
            img {
                width: 200px;
                height: 120px;
                margin-left: 20px;
                border-radius: 3px;
            }
       }
    }
    /deep/ .el-upload {
        .el-upload-dragger {
            width: 280px;
            height: 150px;
            .el-icon-upload {
                margin: 25px 0 0;
            }
        }
    }
}
</style>