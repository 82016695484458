<template>
    <div style="padding: 20px;">
       <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
       <el-button type="primary" icon="el-icon-plus" size="medium" style="margin: 0 0 20px 0;" @click="handleRotationEvent('add')">添加</el-button>
        <template>
            <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                <el-table-column
                label="是否上线"
                align="center"
                width="200">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.onlineFlag">上线</el-tag>
                        <el-tag type="danger" v-else>下线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="轮播图片"
                align="center"
                width="200">
                    <template slot-scope="scope">
                        <img :src="scope.row.image" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center"
                width="130">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" size="medium" @click="handleRotationEvent('edit', scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" size="medium" @click="handleRotationEvent('remove', scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <addRotationVue ref="addRotationPage" :vkey="vkey"></addRotationVue>
    </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../../../components/TableCommon.vue';
import addRotationVue from './components/addRotation.vue';
export default {
    data() {
        return {
            vkey: 'rotationPage',
            formInline: {
                title: '',
                type: '',
                pageNum: 1,
                pageSize: 10
            },
            formList: [
                { label: '轮播图标题', model: 'title', placeholder: '请输入轮播图标题', type: 'input' },
                { label: '类型', model: 'type', placeholder: '请选择', type: 'select', options: [
                    { value: 0, label: 'banner'}, { value: 1, label: '固定'}]
                }
            ],
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '轮播图标题', prop: 'title', align: 'center' },
                { label: '内容', prop: 'content', align: 'center' },
                { label: '类型', prop: 'type', align: 'center' },
                { label: '排序', prop: 'seq', align: 'center' }
            ]
        }
    },
    components: {
        SearchCommon,
        TableCommon,
        addRotationVue
    },
    computed: {
        tableData(){
            let arrs = this.$store.state.manage.views[this.vkey].list || [];
            arrs.map(item=>{
                item.type = item.type == '0' ? 'banner' : '固定'
            })
            return arrs
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList();
    },
    methods: {
        // 列表
        onLoadList(){
           this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getRotationList'
           })
        },
        // 搜索
        onSearch() {
            this.formInline.pageNum = 1;
            this.onLoadList();
        },
        // 清空
        onResetBtn() {
            this.formInline = {
                title: '',
                type: '',
                pageNum: 1,
                pageSize: 10
            }
            this.onLoadList()
        },
        //按钮操作
        handleRotationEvent(type, row) {
            switch(type) {
                case 'add': 
                    this.$nextTick(()=>{
                        this.$refs.addRotationPage.onLoad(type);
                    })
                break;

                case 'edit':
                    this.$nextTick(()=>{
                        this.$refs.addRotationPage.onLoad(type, row);
                    })
                break;

                case 'remove':
                    this.$confirm('确定是否要删除当前选择的数据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('manage/remove', {
                            vkey: this.vkey,
                            query: row.id,
                            requestInterfaceName: 'removeRotation',
                            listInterfaceName: 'getRotationList'
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.formInline.pageNum = 1;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;
            }
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
</style>